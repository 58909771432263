import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { style: {"display":"grid","gap":"var(--padding)","grid-template-columns":"repeat(auto-fill, minmax(200px, 1fr))"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex-column" }
const _hoisted_5 = { class: "bp-download-file-editor__language-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_wysiwyg_editor = _resolveComponent("bp-wysiwyg-editor")!
  const _component_bp_button = _resolveComponent("bp-button")!
  const _component_bp_version_number_editor = _resolveComponent("bp-version-number-editor")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_iframe = _resolveComponent("bp-iframe")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_bp_card, {
      class: "bp-download-file-editor",
      loading: _ctx.loading,
      "fill-height": "",
      "fill-width": ""
    }, {
      header: _withCtx(() => [
        (!_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_translate, {
              key: 0,
              tag: "h2"
            }, {
              default: _withCtx(() => _cache[31] || (_cache[31] = [
                _createTextVNode("New file")
              ])),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_translate, {
              key: 1,
              tag: "h2",
              "translate-params": { file: _ctx.store.getName(_ctx.file) }
            }, {
              default: _withCtx(() => _cache[32] || (_cache[32] = [
                _createTextVNode("Edit file \"%{file}\"")
              ])),
              _: 1
            }, 8, ["translate-params"]))
      ]),
      footer: _withCtx(() => [
        (!_ctx.isEdit && _ctx.file.email_notification)
          ? (_openBlock(), _createBlock(_component_bp_form_button, {
              key: 0,
              disabled: _ctx.saveDisabled,
              errors: _ctx.errors,
              icon: ['far', 'envelope'],
              action: _ctx.showEmailNotificationPreview
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createTextVNode("Show email notification preview")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled", "errors", "action"]))
          : (_openBlock(), _createBlock(_component_bp_form_button, {
              key: 1,
              type: "save",
              disabled: _ctx.saveDisabled,
              errors: _ctx.errors,
              action: _ctx.save
            }, null, 8, ["disabled", "errors", "action"])),
        _createVNode(_component_bp_form_button, {
          type: "reset",
          action: _ctx.reset
        }, null, 8, ["action"]),
        _createVNode(_component_bp_form_button, {
          type: "cancel",
          to: { name: 'admin.download.overview' }
        })
      ]),
      default: _withCtx(() => [
        (_ctx.file && _ctx.operatingSystemStore.loaded)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: "bp-download-file-editor__form",
              onSubmit: _cache[29] || (_cache[29] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createVNode(_component_bp_label, {
                label: _ctx.$gettext('File'),
                required: !_ctx.isEdit
              }, {
                default: _withCtx(() => [
                  (_ctx.$route.params.id)
                    ? (_openBlock(), _createBlock(_component_bp_input, {
                        key: 0,
                        placeholder: `${_ctx.currentAttachmentName} (${_ctx.currentAttachmentSize})`,
                        text: _ctx.$gettext('Current file.'),
                        readonly: ""
                      }, null, 8, ["placeholder", "text"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_bp_input, {
                    text: _ctx.$gettext('Upload a new file.'),
                    onChangeFile: _ctx.uploadAttachment,
                    onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('_attachments', _ctx.$gettext('File'), $event))),
                    required: !_ctx.isEdit,
                    type: "file",
                    modelValue: _ctx.attachmentName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.attachmentName) = $event))
                  }, null, 8, ["text", "onChangeFile", "required", "modelValue"])
                ]),
                _: 1
              }, 8, ["label", "required"]),
              _createVNode(_component_bp_label, {
                label: _ctx.$gettext('Icon'),
                required: ""
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(['default', ..._ctx.customIcons], (value) => {
                      return (_openBlock(), _createBlock(_component_bp_input, {
                        key: value,
                        name: 'icon-selector-' + 'unique',
                        value: value.toLowerCase(),
                        seamless: "",
                        style: {"display":"inline-block"},
                        type: "radio",
                        "model-value": _ctx.file.icon,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.file.icon = $event.toLowerCase())),
                        onChangeValid: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setError('icon', _ctx.$gettext('Icon'), $event)))
                      }, {
                        text: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_bp_icon, {
                              icon: value === 'default'
                  ? _ctx.fileIcon((!!_ctx.attachmentName && _ctx.attachmentName.includes('.'))
                    && _ctx.attachmentName.split('.').pop()
                    || ((!!_ctx.currentAttachmentName && _ctx.currentAttachmentName.includes('.')) && _ctx.currentAttachmentName.split('.').pop() || '???'))
                  : value,
                              style: {"font-size":"3rem"}
                            }, null, 8, ["icon"]),
                            (value !== 'default')
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  innerHTML: _ctx.versionStore.getName(_ctx.versionStore.getByUUIDPath(value)) || value
                                }, null, 8, _hoisted_3))
                              : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                  default: _withCtx(() => _cache[33] || (_cache[33] = [
                                    _createTextVNode("Default")
                                  ])),
                                  _: 1
                                }))
                          ])
                        ]),
                        _: 2
                      }, 1032, ["value", "model-value"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["label"]),
              (_openBlock(), _createBlock(_component_bp_select, {
                data: _ctx.parents,
                label: _ctx.$gettext('Parent folder'),
                transform: _ctx.transformParent,
                key: JSON.stringify(_ctx.parents),
                onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent folder'), $event))),
                required: "",
                modelValue: _ctx.file.parent_id,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.file.parent_id) = $event))
              }, null, 8, ["data", "label", "transform", "modelValue"])),
              _createVNode(_component_bp_wysiwyg_editor, {
                label: _ctx.$gettext('Description'),
                onChangeValid: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setError('description', _ctx.$gettext('Description'), $event))),
                type: "textarea",
                maxlength: 500,
                modelValue: _ctx.file.description,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.file.description) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_wysiwyg_editor, {
                label: _ctx.$gettext('Hint'),
                onChangeValid: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setError('hint', _ctx.$gettext('Hint'), $event))),
                type: "textarea",
                maxlength: 500,
                modelValue: _ctx.file.hint,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.file.hint) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Show checksum'),
                text: _ctx.$gettext('The checksum of the file will be displayed at the end of the hint text.'),
                onChangeValid: _cache[10] || (_cache[10] = ($event: any) => (_ctx.setError('show_checksum', _ctx.$gettext('Show checksum'), $event))),
                flush: "",
                type: "checkbox",
                modelValue: _ctx.file.show_checksum,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.file.show_checksum) = $event))
              }, null, 8, ["label", "text", "modelValue"]),
              _createVNode(_component_bp_label, {
                label: _ctx.$gettext('Version')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_bp_select, {
                    data: _ctx.versionNumbers,
                    transform: _ctx.transformVersionNumber,
                    onChangeValid: _cache[12] || (_cache[12] = ($event: any) => (_ctx.setError('version', _ctx.$gettext('Version'), $event))),
                    clearable: "",
                    "sort-order": "desc",
                    "sort-property": "date",
                    modelValue: _ctx.file.version,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.file.version) = $event))
                  }, null, 8, ["data", "transform", "modelValue"]),
                  _createVNode(_component_bp_button, {
                    class: "flex-0 ws-nw",
                    color: "green",
                    large: "",
                    icon: "plus",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.versionWindow = true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[34] || (_cache[34] = [
                          _createTextVNode("Add new version")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_bp_window, { "model-value": _ctx.versionWindow }, {
                header: _withCtx(() => [
                  _createVNode(_component_translate, { tag: "h2" }, {
                    default: _withCtx(() => _cache[35] || (_cache[35] = [
                      _createTextVNode("Add new version")
                    ])),
                    _: 1
                  })
                ]),
                footer: _withCtx(() => [
                  _createVNode(_component_bp_form_button, {
                    type: "save",
                    errors: _ctx.versionNumberEditor?.errors,
                    action: _ctx.saveVersion
                  }, null, 8, ["errors", "action"]),
                  _createVNode(_component_bp_form_button, {
                    type: "reset",
                    action: _ctx.versionNumberEditor?.reset
                  }, null, 8, ["action"]),
                  _createVNode(_component_bp_form_button, {
                    type: "cancel",
                    action: () => _ctx.versionWindow = false
                  }, null, 8, ["action"])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_bp_version_number_editor, {
                    "label-position": "top",
                    redirect: false,
                    ref: "versionNumberEditor",
                    unwrap: "",
                    modelValue: _ctx.versionNumber,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.versionNumber) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["model-value"]),
              (!_ctx.isEdit && !!_ctx.file.version)
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    key: 0,
                    label: _ctx.$gettext('Email notification'),
                    text: _ctx.$gettext('Send an email to all registered users who accepted to be notified on release of a new version.'),
                    onChangeValid: _cache[16] || (_cache[16] = ($event: any) => (_ctx.setError('notification', _ctx.$gettext('Email notification'), $event))),
                    flush: "",
                    type: "checkbox",
                    modelValue: _ctx.file.email_notification,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.file.email_notification) = $event))
                  }, null, 8, ["label", "text", "modelValue"]))
                : _createCommentVNode("", true),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Date'),
                onChangeValid: _cache[18] || (_cache[18] = ($event: any) => (_ctx.setError('date', _ctx.$gettext('Date'), $event))),
                type: "date",
                modelValue: _ctx.file.date,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.file.date) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Order'),
                onChangeValid: _cache[20] || (_cache[20] = ($event: any) => (_ctx.setError('order', _ctx.$gettext('Order'), $event))),
                type: "number",
                modelValue: _ctx.file.order,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.file.order) = $event))
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_bp_label, {
                label: _ctx.$gettext('Compatibilities')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_bp_input, {
                      flush: "",
                      type: "checkbox",
                      "model-value": _ctx.file.compatibilities !== null,
                      text: _ctx.$gettext('Show compatibilities'),
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (_ctx.file.compatibilities = $event ? [] : null))
                    }, null, 8, ["model-value", "text"]),
                    _createVNode(_component_bp_select, {
                      data: _ctx.operatingSystemStore.getVersions(),
                      disabled: _ctx.file.compatibilities === null,
                      "model-value": _ctx.file.compatibilities || [],
                      transform: _ctx.operatingSystemStore.getName,
                      placeholder: _ctx.file.compatibilities === null ? undefined : _ctx.store.getCompatibilities(_ctx.file.parent_id || '').map(os => _ctx.removeHTML(_ctx.operatingSystemStore.getName(_ctx.operatingSystemStore.getById(os)))).join(', '),
                      onChangeValid: _cache[23] || (_cache[23] = ($event: any) => (_ctx.setError('compatibilities', _ctx.$gettext('Compatibilities'), $event))),
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (_ctx.file.compatibilities = $event)),
                      clearable: "",
                      multiple: "",
                      "sort-order": "desc"
                    }, null, 8, ["data", "disabled", "model-value", "transform", "placeholder"])
                  ])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_bp_input, {
                label: _ctx.$gettext('Featured'),
                text: _ctx.$gettext('Feature this file in the menu and at the top of the download area.'),
                onChangeValid: _cache[25] || (_cache[25] = ($event: any) => (_ctx.setError('featured', _ctx.$gettext('Featured'), $event))),
                flush: "",
                type: "checkbox",
                modelValue: _ctx.file.featured,
                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.file.featured) = $event))
              }, null, 8, ["label", "text", "modelValue"]),
              (_ctx.file.featured)
                ? (_openBlock(), _createBlock(_component_bp_input, {
                    label: _ctx.$gettext('Featured text'),
                    key: _ctx.file.parent_id,
                    text: _ctx.$gettext('This is a replacement title for this file which will be displayed instead of the default generated one, e.g. "Latest file" in the main menu.'),
                    placeholder: _ctx.store.getFeaturedFolderName(_ctx.file) || _ctx.store.getFeaturedName(_ctx.file),
                    onChangeValid: _cache[27] || (_cache[27] = ($event: any) => (_ctx.setError('featured', _ctx.$gettext('Featured'), $event))),
                    modelValue: _ctx.file.featured_text,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.file.featured_text) = $event))
                  }, null, 8, ["label", "text", "placeholder", "modelValue"]))
                : _createCommentVNode("", true)
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["loading"]),
    (_ctx.emailNotificationPreview)
      ? (_openBlock(), _createBlock(_component_bp_window, {
          key: 0,
          modelValue: _ctx.emailNotificationWindow,
          "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.emailNotificationWindow) = $event))
        }, {
          header: _withCtx(() => [
            _createVNode(_component_translate, { tag: "h2" }, {
              default: _withCtx(() => _cache[37] || (_cache[37] = [
                _createTextVNode("Email notification preview")
              ])),
              _: 1
            })
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_bp_form_button, {
              icon: ['far', 'floppy-disk'],
              color: "green",
              disabled: _ctx.saveDisabled,
              errors: _ctx.errors,
              action: _ctx.save
            }, {
              default: _withCtx(() => [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => _cache[39] || (_cache[39] = [
                    _createTextVNode("Save and send email notifications")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["disabled", "errors", "action"]),
            _createVNode(_component_bp_form_button, {
              type: "cancel",
              action: _ctx.closeEmailNotificationPreview
            }, null, 8, ["action"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_translate, {
              tag: "p",
              "translate-n": _ctx.emailNotificationPreview.users,
              "translate-params": { user_count: _ctx.emailNotificationPreview.users },
              "translate-plural": "The following email will be sent to <strong>%{user_count} users</strong>:"
            }, {
              default: _withCtx(() => _cache[38] || (_cache[38] = [
                _createTextVNode("The following email will be sent to "),
                _createElementVNode("strong", null, "%{user_count} user", -1),
                _createTextVNode(":")
              ])),
              _: 1
            }, 8, ["translate-n", "translate-params"]),
            _createElementVNode("h3", null, _toDisplayString(_ctx.emailNotificationPreview[_ctx.emailNotificationPreviewLanguage]?.subject), 1),
            _createVNode(_component_bp_iframe, {
              "model-value": _ctx.emailNotificationPreview[_ctx.emailNotificationPreviewLanguage]?.body || ''
            }, null, 8, ["model-value"]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.availableLanguages).sort((a, b) => a < b ? -1 : 1), (lang, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: lang.toLowerCase()
                }, [
                  (index !== 0)
                    ? (_openBlock(), _createBlock(_component_bp_icon, {
                        key: 0,
                        icon: "slash-forward",
                        "fixed-width": false
                      }))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_resolveDynamicComponent('bp-input'), {
                    "model-value": _ctx.emailNotificationPreviewLanguage,
                    name: 'language-selector-' + _ctx.unique,
                    text: _ctx.getTranslatedName(lang),
                    value: _ctx.getTranslatedKey(lang),
                    "onUpdate:modelValue": _ctx.setLanguage,
                    seamless: "",
                    type: "radio"
                  }, null, 8, ["model-value", "name", "text", "value", "onUpdate:modelValue"]))
                ], 64))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}