import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-download-file-editor-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_download_file_editor = _resolveComponent("bp-download-file-editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_bp_view_header, {
      class: "bp-download-editor-view__header",
      image: "/static/background/app_background_download.jpg",
      icon: ['fad', 'file']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Download File Editor")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_bp_download_file_editor, {
      modelValue: _ctx.download,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.download) = $event)),
      loading: _ctx.store.isLoading() || _ctx.operatingSystemStore.isLoading()
    }, null, 8, ["modelValue", "loading"])
  ]))
}