
import { $gettext } from 'vue-gettext';
import { Attachment, defaultFile, EmailNotificationPreview, File, Folder, useDownloadStore } from '@/stores/download';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { formatBytes, removeHTML, uuid } from '@/utils/string';
import { availableLanguages, getTranslated, getTranslatedKey, getTranslatedName, TranslatedKey } from '@/translation';
import { isEqual, OptionalKeys } from '@/utils/object';
import { PopoverElement } from '../popover/BpPopoverMenu';
import { customIcons, fileIcon, slashIcon } from '../icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { useOperatingSystemStore } from '@/stores/operatingSystem';
import { useRouter } from 'vue-router';
import { useVersionStore, VersionNumber } from '@/stores/version';
import BpToast from '../toast/BpToasts';
import BpVersionNumberEditorVue from '../version/BpVersionNumberEditor.vue';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-download-file-editor',
  components: {
    BpVersionNumberEditor: BpVersionNumberEditorVue,
  },
  props: {
    modelValue: Object as PropType<File>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------
    
    const store = useDownloadStore();

    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<File>();

    ///-------------------------------------------------------------------
    /// FILE
    ///-------------------------------------------------------------------

    const initialFile: OptionalKeys<File, '_id' | '_rev'> = {
      ...defaultFile,
      parent_id: (router.currentRoute.value.query.parent as string) || 'root',
    };

    const file = ref<OptionalKeys<File, '_id' | '_rev'>>({ ...clone(initialFile), ...props.modelValue ? clone(props.modelValue) : {} });

    ///-------------------------------------------------------------------
    /// ATTACHMENT
    ///-------------------------------------------------------------------

    const attachment = ref<Attachment | null>(null);
    const attachmentName = ref('');

    const currentAttachment = computed(() => store.getAttachment(file.value as File));
    watch (() => currentAttachment.value, attachment => {
      const name = $gettext('File');
      if (attachment && errors.value.has(name)) {
        errors.value.delete(name);
      }
    }, { immediate: true })
    const currentAttachmentName = computed(() => store.getName(file.value as File))
    const currentAttachmentSize = computed(() => formatBytes(currentAttachment.value?.length || 0))

    function uploadAttachment(name: string, data: string) {
      attachment.value = { name, data };
    }

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      attachment.value = null;
      attachmentName.value = '';
      if (props.modelValue && isEqual(file.value, props.modelValue)) { return }
      file.value = { ...clone(initialFile), ...props.modelValue ? clone(props.modelValue) : {} };
    }
    watch(() => props.modelValue, reset, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// EMAIL NOTIFICATION PREVIEW
    ///-------------------------------------------------------------------

    const emailNotificationPreview = ref<EmailNotificationPreview>();
    const emailNotificationPreviewLanguage = ref<TranslatedKey>('de');
    const emailNotificationWindow = ref(false);
    const unique = uuid();
    
    async function showEmailNotificationPreview() {
      const preview = await store.previewEmailNotification(file.value);
      if (typeof preview === 'string') return;
      emailNotificationPreview.value = preview;
      emailNotificationWindow.value = true;
    }

    function closeEmailNotificationPreview() {
      emailNotificationWindow.value = false;
      emailNotificationPreview.value = undefined;
    }

    function setLanguage(lang: string) {
      emailNotificationPreviewLanguage.value = getTranslatedKey(lang);
    }

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    async function save() {
      let response;
      file.value.doc_type = 'download_file';
      if (file.value._id) {
        response = await store.update(file.value, attachment.value);
      } else {
        response = await store.create(file.value, attachment.value);
      }
      if (response?.success) {
        emailNotificationWindow.value = false;
        BpToast.show({
          color: 'green',
          title: file.value._id
            ? $gettext('File successfully updated')
            : $gettext('File successfully created'),
          content: file.value._id
            ? $gettext('The file was successfully updated.')
            : $gettext('The file was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.download.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: file.value._id
            ? $gettext('Failed to update file')
            : $gettext('Failed to create file'),
          content: file.value._id
            ? $gettext('The file could not be updated: %{error}', { error: response?.error })
            : $gettext('The file could not be created: %{error}', { error: response?.error }),
          icon: 'circle-check',
        });
      }
    }

    const saveDisabled = computed(() => errors.value.size > 0);

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    const parents = ref<PopoverElement[]>([]);
    watch(() => [store.isLoading(), file.value], () => {
      if (!store.loaded || store.isLoading() || !file.value) { return }
      parents.value = store.getParents({ excludeIds: file.value._id, includeRoot: true })
        .map(parent => {
          return ({ ...parent, icon: (parent._id === 'root' ? slashIcon(['far', 'folder']) : ['far', 'folder']) })
        }) as PopoverElement[];
    }, { immediate: true, deep: true });

    const transformParent = (parent: Folder) => {
      const path = store.getPathById(parent._id);
      if (path.length > 0) {
        return `${getTranslated(parent.name)} <em style="opacity: 0.5;">(${path.map(parent => getTranslated(parent.name)).join(' > ')})</em>`;
      } else if (parent._id === 'root') {
        return `<em style="opacity: 0.5;">(${getTranslated(parent.name)})</em>`
      }
      return getTranslated(parent.name) || '';
    };

    ///-------------------------------------------------------------------
    /// VERSIONS
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();

    const versionNumbers = computed(() => {
      return versionStore.getVersionNumbers().map(version => ({ ...version, icon: 'v' }))
    });

    const transformVersionNumber = (versionNumber: VersionNumber) => {
      const path = versionStore.getPathById(versionNumber._id);
      const component = path
        .filter(component => component.doc_type === 'version_component')
        .map(component => versionStore.getName(component))
        .join(' ');
      const product = path
        .filter(product => product.doc_type === 'version_product')
        .map(product => versionStore.getName(product))
        .join(' ');

      if (component && product) {
        return `${component} ${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      } else if (component) {
        return `${component} ${versionNumber.version}`;
      } else if (product) {
        return `${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      }
      return versionNumber.version;
    }

    const versionNumber = ref<VersionNumber>();
    const versionNumberEditor = ref<typeof BpVersionNumberEditorVue>();

    const versionWindow = ref(false);

    async function saveVersion() {
      const version = await versionNumberEditor.value?.save();
      file.value.version = version._id;
      versionWindow.value = false;
    }

    ///-------------------------------------------------------------------
    /// COMPATIBILITIES
    ///-------------------------------------------------------------------
    
    const operatingSystemStore = useOperatingSystemStore();

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      attachmentName,
      availableLanguages,
      getTranslatedName,
      getTranslatedKey,
      setLanguage,
      closeEmailNotificationPreview,
      currentAttachmentName,
      currentAttachmentSize,
      customIcons,
      errors,
      file,
      fileIcon,
      formatBytes,
      isEdit,
      operatingSystemStore,
      parents,
      removeHTML,
      reset,
      save,
      saveDisabled,
      saveVersion,
      setError,
      slashIcon,
      unique,
      store,
      emailNotificationPreview,
      emailNotificationPreviewLanguage,
      emailNotificationWindow,
      showEmailNotificationPreview,
      transformParent,
      transformVersionNumber,
      uploadAttachment,
      versionNumber,
      versionNumberEditor,
      versionNumbers,
      versionStore,
      versionWindow,
    }
  }
})


